<template>
  <div>
    <nuxt-link to="/" class="block mt-9 lg:hidden">
      <t-icon-logo-footer class="w-52 h-13 mx-auto" />
    </nuxt-link>
    <t-header class="hidden lg:block" />
    <slot />
    <t-footer class="hidden lg:block" />
    <t-mobile-footer class="block h-20 lg:hidden" />
  </div>
</template>

<script setup>
import { v4 as uuid } from 'uuid'
import { Blinkloader } from '../utils/blinkloader'

const deviceId = useCookie(COOKIE_KEYS.deviceId)

if (!deviceId.value) {
  deviceId.value = uuid()
}

onMounted(() => {
  Blinkloader.optimize({
    pubkey: '5a084c8fdde49694365a',
    fadeIn: true,
    lazyload: true,
    smartCompression: true,
    responsive: true,
    retina: true,
    webp: true
  })
})
</script>
